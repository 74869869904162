$(function() {
  // Datepicker オプションの初期値定義を変更
  $.datepicker.setDefaults({
    // 日本語へローカライズ
    // Cf. https://webllica.com/jquery-ui-datepicker/
    closeText: '閉じる',
    prevText: '<前',
    nextText: '次>',
    currentText: '今日',
    monthNames: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
    monthNamesShort: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
    dayNames: ['日曜日','月曜日','火曜日','水曜日','木曜日','金曜日','土曜日'],
    dayNamesShort: ['日','月','火','水','木','金','土'],
    dayNamesMin: ['日','月','火','水','木','金','土'],
    weekHeader: '週',
    dateFormat: 'yy-mm-dd',
    firstDay: 0,
    isRTL: false,
    showMonthAfterYear: true,
    yearSuffix: '年',
    // 複数月のカレンダー表示
    // Cf. https://webllica.com/jquery-ui-datepicker-multiple-months/
    numberOfMonths: 1,     // 1ヶ月分表示
    showCurrentAtPos: 0,   // 現在の月の表示位置は一番
    stepMonths: 1,         // 月の移動を1ヶ月単位とする
    showButtonPanel: true, // ボタンパネルを表示
    gotoCurrent: true,     // ボタンパネルに当日日付のボタン表示
    // カレンダーを月曜始まりにする
    // Cf. https://webllica.com/jquery-ui-datepicker-starting-on-mondays/
    firstDay: 1,    // 月曜始まり
    // 年月をプルダウン選択にする
    // Cf. https://webllica.com/jquery-ui-datepicker-select-pulldown/
    changeYear: false,  // 年選択をプルダウン化
    changeMonth: false, // 月選択をプルダウン化
    // カレンダーアイコンを用意する
    // Cf. https://webllica.com/jquery-ui-datepicker-by-calendar-icon/
    //buttonImage: "/images/calender.png",        // カレンダーアイコン画像
    //buttonText: "・・・", // ツールチップ表示文言
    //buttonImageOnly: false,           // 画像として表示
    //showOn: "both"                   // カレンダー呼び出し元の定義
  });
  // 初期表示
  $.fn.InitializeDatePicker = function(){
    const INPUT_GROUP_SUFFIX = '_input_group';
    $.each($('.datetimepicker-input:visible, .datetimepicker-input-noselect:visible'), function(i, value){
      var elemBase = $(this);
      var elemBaseName = elemBase.attr('id');
      var elemBaseClass = elemBase.attr('class');
      elemBase.wrap('<div id="' + elemBaseName + INPUT_GROUP_SUFFIX + '" class="input-group"></div>');
      elemBaseInputGroup = $("#" + elemBaseName + INPUT_GROUP_SUFFIX);
      elemBaseInputGroup.prepend('<div class="input-group-prepend input-group-calendar"><span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true" ></span></i></div>');
      if (elemBaseClass.indexOf('datetimepicker-input-noselect') === -1 ){
        elemBaseInputGroup.append('<div class="input-group-append input-group-clear"><span class="input-group-text"><i class="fa fa-times" aria-hidden="true"></i></span></div>');
      }
    });
  }
  // ［×］ボタンをクリックした時
  $(document).on('click', '.input-group-clear', function() {
    var elemBaseParent = $(this).parent();
    var elemBaseInput = elemBaseParent.find('input');
    elemBaseInput.val('')
  });
  // CALENDARアイコンをクリックした時
  $(document).on('click', '.input-group-calendar', function() {
    var elemBaseParent = $(this).parent();
    var elemBaseInput = elemBaseParent.find('input');
    elemBaseInput.datepicker('show');
  });
  // カレンダー表示
  $('.datetimepicker-input, .datetimepicker-input-noselect').datepicker();
  $('.datetimepicker-input, .datetimepicker-input-noselect').InitializeDatePicker();
  return
});