$(function() {

  $(document).on('click', '.nestfields-add', function(e) {
    link = this;

    // リンクまたはイベントが関数に渡されなかった場合、関数の実行を停止します。
    if (!link || !e) return

    // 最大行数をチェック
    if ($(".nested-fields:visible").length >= link.dataset.maxrow) return

    // ブラウザがURLをたどらないようにします。
    e.preventDefault()
    // 一意のタイムスタンプを保存して、関連する配列のキーが一意になるようにします。
    let time = new Date().getTime()
    // データID属性を変数に保存します。 これは「new_object.object_id」に対応します。
    let linkId = link.dataset.id
    // `linkId`に値がある場合、fieldsデータ属性で使用される` new_object.object_id`のインスタンスを見つけるために必要な新しい正規表現を作成します。
    let regexp = linkId ? new RegExp(linkId, 'g') : null
    // `new_object.object_id`のすべてのインスタンスを` time`に置き換え、 `regexp`に値がある場合はマークアップを変数に保存します。
    let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null
    // 追加するフィールドがある場合は、フォームに新しいマークアップを追加します。
    //newFields ? link.insertAdjacentHTML('beforebegin', newFields) : null
    newFields ? $(newFields).appendTo('.' + link.dataset.target) : null
    // 画面の縦スクロールを下に移動します。
    var xheight =  $(document).height();
    $(window).scrollTop(xheight);
    //イベントトリガーを発動
    $(this).trigger('nestfields:add:after');
  });
  $(document).on('click', '.nestfields-remove', function(e) {
    link = this;
    // リンクまたはイベントが関数に渡されなかった場合、関数の実行を停止します。
    if (!link || !e) return
    // ブラウザがURLをたどらないようにします。
    e.preventDefault()
    // ネストされたフィールドのセットの親ラッパーを見つけます。
    let fieldParent = link.closest('.nested-fields')
    // 親ラッパーがある場合は、非表示の削除フィールドを見つけます。
    let deleteField = fieldParent
        ? fieldParent.querySelector('._destroy')
        : null
    // 削除フィールドがある場合は、値を「1」に更新し、対応するネストされたフィールドを非表示にします。
    if (deleteField) {
      deleteField.value = true
      fieldParent.style.display = 'none'
    }
    //URLからモデル名を取得する。
    $(this).trigger('nestfields:remove:after');
  });
});