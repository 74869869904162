$(function() {
  // 行追加
  var rowAdd = function(keycode, elemBase){
    if (keycode === 13 || keycode === ''){
      elemLast = $('.inventory_input').find('div.nested-fields:visible');
      var elemLastQuantity = elemLast.slice(-1).find('.quantity');
      if (elemLastQuantity.attr('id') === elemBase.attr('id')){
        $('.nestfields-add').trigger('click');
        elemLast = $('.inventory_input').find('div.nested-fields:visible');
        elemLast.slice(-1).find('.barcode').focus();
      }
    }
  }
  // 行番号採番
  var rowNumbering = function (){
    var rowCounter = 0;
    $('.inventory_input').find('div.nested-fields').each(function(idx) {
      var rowElement = $(this);
      if(rowElement.css("display") === "none") {
        rowElement.find('.rowno').val(0);
      }else{
        rowCounter++;
        rowElement.find('.rowno').val(rowCounter);
      }
    });
  }

  // 行追加をクリックした時
  $('.inventory_input').on('nestfields:add:after', '.nestfields-add', function() {
    rowNumbering();
    var elemBaseNestedFields = $('.nested-fields:last-child');
    var elemBaseBarcodeReader = elemBaseNestedFields.find('.barcode');
    elemBaseBarcodeReader.BarcodeReader();
  });

  // 行削除をクリックした時
  $('.inventory_input').on('nestfields:remove:after', '.nestfields-remove', function() {
    rowNumbering();
  });

  // 数量のフォーカスが外れた時
  $('.inventory_input').on('keydown', '.quantity', function() {
    // 行追加
    rowAdd(event.keyCode, $(this))
  });
  // 数量のフォーカスが外れた時
  $('.inventory_input').on('blur', '.quantity', function() {
    // 行追加
    rowAdd('', $(this))
  });

  // 商品コードのフォーカスが外れた時。
  $('.inventory_input').on('blur', '.product_autocomplete', function() {
    // 諸口コード
    const sundryItemsCode = 9999999999;
    //JANコード用要素
    var product_jancode = $(this);
    var work1 = product_jancode.attr('ID').split('_');
    var base_element_name = work1[0] + "_" + work1[1] + "_" + work1[2] + "_" + work1[3] + "_";
    //商品用コード要素
    var elemProductId = $('#' + base_element_name + "product_id");
    //商品名要素
    var elemProductName = $('#' + base_element_name + "product_name");
    // 数量要素
    var elemQuantity = $('#' + base_element_name + "quantity");
    //商品JANコードの空白を削除
    product_jancode.val($.trim(product_jancode.val()));
    //検索パラメータにJANコードを設定
    var param = {};
    param['jancode'] = product_jancode.val();
    //商品情報を取得
    $.ajax({
      url : '/products/jancode_search',
      type : "POST",
      cache : false,
      dataType : "json",
      data : param,
      success : function(list){
        if(IsBlank(list) == false){
          if (ToNumber(elemProductId.val()) === Number(list.id)){
            //console.log('前回と同じ！！！')
          }else{
            //商品IDを表示
            elemProductId.val(list.id);
            //商品名を表示
            elemProductName.val(list.name);
            //数量要素にフォーカス移動
            elemQuantity.focus();
          }
        }else{
          if (Number(elemProductId.val()) === sundryItemsCode){
            //console.log('前回と同じ！！！')
          }else{
            //商品IDを表示
            elemProductId.val(sundryItemsCode);
            //商品名を表示
            elemProductName.val('');
            //商品名要素にフォーカス移動
            elemProductName.focus();
          }
        }
      }, error: function(){
        //商品IDを表示
        elemProductId.val('');
        //商品名を表示
        elemProductName.val('接続失敗');
      }
    });
  });
  return
});