$(function() {
  $('.table tbody tr').click(function() {
    return window.location.href = $(this).attr('data_link');
  });

  // 画面表示時
  $(document).ready(function(){
    // カーソルの初期位置を設定
    var elemFirstCursor = $(".firstcursor")
    elemFirstCursor.focus();
  });

  // 得意先サジェスト機能
  $('.CustomerSuggest').Suggest({
    msModel: 'customers'
  });

  // バーコードリーダ用の要素がフォーカスを取得したとき
  $('.barcode').BarcodeReader();

  // キー押下時
  $(document).on('keydown', '.EnterSubmit', function() {
    var k;
    k = event.keyCode;
    if (k == 13 ){
      $('form').submit();
    }
  });

  //SELECT2の設定
  $('.selectpicker').select2();
  return
});
