/**
 * 数値の3桁カンマ区切り
 * 入力値をカンマ区切りにして返却
 * [引数]   numVal: 入力数値
 * [返却値] String(): カンマ区切りされた文字列
 */
window.addFigure = function(numVal, DecimalPoint) {
  var numData;
  if (IsBlank(numVal) === true) {
    return '';
  }
  numVal = numVal.toString();
  numVal = toHalfWidth(numVal).replace(/,/g, '').trim();
  if (!/^[+|-]?(\d*)(\.\d+)?$/.test(numVal)) {
    return numVal;
  }
  numData = numVal.toString().split('.');
  //numData[0] = Number(numData[0]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  numData[0] = numData[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (parseInt(DecimalPoint) === 0) {
    return numData[0];
  } else {
    if (IsBlank(numData[1]) || !IsNumeric(numData[1])) {
      numData[1] = '0000000000'.slice(0, DecimalPoint);
    } else {
      numData[1] = (numData[1] + '0000000000').slice(0, DecimalPoint);
    }
    return numData.join('.');
  }
};
  /**
 * 全角から半角への変換関数
 * 入力値の英数記号を半角変換して返却
 * [引数]   strVal: 入力値
 * [返却値] String(): 半角変換された文字列
 */
window.toHalfWidth = function(strVal) {
  var halfVal;
  halfVal = strVal.replace(/[！-～]/g, function(tmpStr) {
    return String.fromCharCode(tmpStr.charCodeAt(0) - 0xFEE0);
  });
  return halfVal;
};
/**
 * カンマ外し
 * 入力値のカンマを取り除いて返却
 * [引数]   strVal: 半角でカンマ区切りされた数値
 * [返却値] String(): カンマを削除した数値
 */
window.delFigure = function(strVal) {
  return strVal.replace(/,/g, '');
};

/**
 * 引数が空白か判定関数してBooleanを返却
 * [引数]   x: 値
 * [返却値] Boolean(): null or "" : TRUE
 *                     以外       : FALSE
 */
window.IsBlank = function(x) {
  if (typeof x === "undefined") {
    return true;
  } else {
    if (x === null) {
      return true;
    } else {
      if (x === "") {
        return true;
      } else {
        return false;
      }
    }
  }
};
/**
 * 引数が数値か判定関数してBooleanを返却
 * [引数]   x: 値
 * [返却値] Boolean(): 数値 : TRUE
 *                     以外 : FALSE
 */
window.IsNumeric = function(x) {
  return $.isNumeric(x);
};
/**
 * 文字型を数値に変換
 * [引数]   x: 値
 * [返却値] x(): 数値
 */
window.ToNumber = function(x) {
  if (IsBlank(x)) {
    return 0;
  }
  x = delFigure(x);
  if (!IsNumeric(x)) {
    return 0;
  }
  return Number(x);
};
/**
 * 乗算（掛け算）を行う
 * [引数]   x: 値
 *          y: 値
 * [返却値] Number()
 */
window.MultPlication = function(x, y){
  var ret = ToNumber(x) * ToNumber(y);
  return ret;
}

/**
 * 乗算（掛け算）を行う
 * [引数]   x: 値
 *          tax_fraction: 0：切り捨て、5：四捨五入、9：切り上げ
 * [返却値] Number()
 */
window.TaxCalculation = function(x, tax_fraction){
  switch (tax_fraction){
    case 0:
      // 切り捨て
      ret = Math.floor(x * 0.08);
      break;
    case 5:
      // 四捨五入
      ret = Math.round(x * 0.08);
      break;
    case 9:
      // 切り上げ
      ret = Math.ceil(x * 0.08);
      break;
    default:
      ret = x;
      break;
  }
  return ret;
}

/**
 * nestfieldsの形式に合わせた要素IDの作成
 * [引数]   arg: 値
 *          arg2: 値
 * [返却値] String()
 */
window.NextFocusElementId = function(arg, arg2){
  if (IsBlank(arg) === false){
    var NextElementIdSplit = arg.split('_');
    var NextElementId = NextElementIdSplit[0] + "_" + NextElementIdSplit[1] + "_" + NextElementIdSplit[2] + "_" + NextElementIdSplit[3] + "_" + arg2
    return NextElementId;
  } else {
    return '';
  }
};
