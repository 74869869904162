// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("jquery-ui/ui/widgets/datepicker")
require("trix")
require("@rails/actiontext")

import "../source/common/Suggest.js";                               // 得意先サジェスト機能
import "../source/common/CommonLib.js";                             // 共通関数
import "../source/common/InputNumber.js";                           // 数値入力
import "../source/common/Barcode.js";                               // バーコード読み込み
import "../source/common/DatePicker.js";                            // 日付入力機能
import "../source/chosen/chosen.jquery.js";                         // コンボボックス拡張
import "../source/application.js";
import "../source/nestfields/nestfields-event.js"                   // 入力用明細
import "../source/salesslip.js";                                    // 売上伝票入力
import "../source/returnslip.js";                                   // 返品伝票入力
import "../source/inventory.js";                                    // 売上伝票入力

//bootstrap
import "bootstrap";
import '@fortawesome/fontawesome-free/js/all'
import "../stylesheets/application.scss";
import "../stylesheets/common/Suggest.scss";                        // 得意先サジェスト用スタイルシート
import "../stylesheets/common/Barcode.scss";                        // バーコードリーダ用スタイルシート
import "../stylesheets/common/DatePicker.scss";                     // 日付入力用スタイルシート

// select2 の CSS・JS・エラーメッセージの日本語化
require("select2/dist/css/select2");                                 // select2 本体のCSSの読み込み
require("select2-bootstrap-theme/dist/select2-bootstrap");           // select2 用の bootstrap テーマの読み込み
import Select2 from "select2";
require("select2/dist/js/i18n/ja");
// グローバルなオプションの指定
//$.fn.select2.defaults.set("allowClear", true)    // 空にできるようにする
//$.fn.select2.defaults.set("placeholder", "")     // allowClear だけだとエラーになるため
$.fn.select2.defaults.set("width", "100%")       // tdタグの中に入れたときにだんだん大きくなっていくのを防ぐ
//$.fn.select2.defaults.set("theme", "bootstrap");                     // テーマの指定

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// <%= asset_pack_path("media/images/auth-backimage.jpg") %>
