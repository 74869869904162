$(function() {
  const elemStartingPoint = '.returnslip_input'
  // 行追加
  var rowAdd = function(keycode, elemBase){
    if (keycode === 13 || keycode === ''){
      elemLast = $(elemStartingPoint).find('div.nested-fields:visible');
      var elemLastCostPrice = elemLast.slice(-1).find(".selling_price");
      if (elemLastCostPrice.attr("id") === elemBase.attr("id")){
        $('.nestfields-add').trigger('click');
        elemLast = $(elemStartingPoint).find('div.nested-fields:visible');
        elemLast.slice(-1).find(".barcode").focus();
      }
    }
  }

  // 行番号採番
  var rowNumbering = function (){
    var rowCounter = 0;
    $(elemStartingPoint).find('div.nested-fields').each(function(idx) {
      var rowElement = $(this);
      if(rowElement.css("display") === "none") {
        rowElement.find('.rowno').val(0);
      }else{
        rowCounter++;
        rowElement.find('.rowno').val(rowCounter);
      }
    });
  }

  // 売上金額＆合計金額集計
  var amount_sum = function(){
    var unit_price_sum = 0;
    var cost_price_sum = 0;
    var selling_price_sum = 0;
    // 消費税区分を取得
    var hb_tek_tax_fraction = ToNumber($('.customer').attr('hb_tek_tax_fraction'));
    $(elemStartingPoint).find('div.nested-fields:visible').each(function(idx) {
      var rowElement = $(this);
      //単価取得
      var elemUnitPrice = rowElement.find('.unit_price');
      //原価取得
      var elemCostPrice = rowElement.find('.cost_price');
      //売価取得
      var elemSellingPrice = rowElement.find('.selling_price');
      //数量
      var elemQuantity = rowElement.find('.quantity');
      //単価計取得
      var elemUnitAmount = rowElement.find('.unit_amount');
      //原価計取得
      var elemCostAmount = rowElement.find('.cost_amount');
      //売価計取得
      var elemSellingAmount = rowElement.find('.selling_amount');

      //単価計取得
      elemUnitAmount.val(MultPlication(elemQuantity.val(), elemUnitPrice.val()));
      //原価計取得
      elemCostAmount.val(MultPlication(elemQuantity.val(), elemCostPrice.val()));
      //売価計取得
      elemSellingAmount.val(MultPlication(elemQuantity.val(), elemSellingPrice.val()));

      // 単価伝票合計
      unit_price_sum = unit_price_sum + MultPlication(elemQuantity.val(), elemUnitPrice.val());
      // 原価伝票合計
      cost_price_sum = cost_price_sum + MultPlication(elemQuantity.val(), elemCostPrice.val());
      // 売価伝票合計
      selling_price_sum = selling_price_sum + MultPlication(elemQuantity.val(), elemSellingPrice.val())
    });
    // 小計金額表示
    var elemReturnAmount = $(elemStartingPoint).find('input.return_amount');
    elemReturnAmount.val(addFigure(unit_price_sum, elemReturnAmount.attr('dp')));

    // 消費税金額表示
    var elemTaxAmount = $(elemStartingPoint).find('input.tax_amount');
    elemTaxAmount.val(addFigure(TaxCalculation(unit_price_sum, hb_tek_tax_fraction), elemTaxAmount.attr('dp')));

    //合計金額表示
    var elemTotalAmount = $(elemStartingPoint).find('input.total_amount');
    elemTotalAmount.val(addFigure(unit_price_sum + TaxCalculation(unit_price_sum, hb_tek_tax_fraction), elemTotalAmount.attr('dp')));
  }

  // 数量のフォーカスが外れた時
  $(elemStartingPoint).on('blur', '.quantity', function() {
    amount_sum();
  });

  // 単価のフォーカスが外れた時
  $(elemStartingPoint).on('blur', '.unit_price', function() {
    amount_sum();
  });

  // 売価のフォーカスが外れた時
  $(elemStartingPoint).on('keydown', '.selling_price', function() {
    // 行追加
    rowAdd(event.keyCode, $(this))
  });

  // 売価のフォーカスが外れた時
  $(elemStartingPoint).on('blur', '.selling_price', function() {
    amount_sum();
    // 行追加
    rowAdd("", $(this))
  });

  // 行追加をクリックした時
  $(elemStartingPoint).on('nestfields:add:after', '.nestfields-add', function() {
    rowNumbering();
    amount_sum();
    var elemBaseNestedFields = $('.nested-fields:last-child');
    var elemBaseBarcodeReader = elemBaseNestedFields.find('.barcode');
    elemBaseBarcodeReader.BarcodeReader();
  });

  // 行削除をクリックした時
  $(elemStartingPoint).on('nestfields:remove:after', '.nestfields-remove', function() {
    rowNumbering();
    amount_sum();
  });

  // 商品コードのフォーカスが外れた時。
  $(elemStartingPoint).on('blur', '.product_autocomplete', function() {
    // 諸口コード
    const sundryItemsCode = 9999999999;
    //JANコード用要素
    var product_jancode = $(this);
    var work1 =  product_jancode.attr('ID').split('_');
    var base_element_name = work1[0] + "_" + work1[1] + "_" + work1[2] + "_" + work1[3] + "_";
    //商品用コード要素
    var elemProductId = $('#' + base_element_name + "product_id");
    //商品名要素
    var elemProductName = $('#' + base_element_name + "product_name");
    //単価要素
    var elemUnitPrice = $('#' + base_element_name + "unit_price");
    //原価要素
    var elemCostPrice = $('#' + base_element_name + "cost_price");
    //売価要素
    var elemSellingPrice = $('#' + base_element_name + "selling_amount");
    //単価計要素
    var elemUnitAmount = $('#' + base_element_name + "unit_amount");
    //原価計要素
    var elemCostAmount = $('#' + base_element_name + "cost_amount");
    //売価計要素
    var elemSellingAmount = $('#' + base_element_name + "selling_amount");
    //数量要素
    var elemQuantity = $('#' + base_element_name + "quantity");
    //得意先コード要素
    var customer = $('.customer');
    //商品JANコードに空白を削除
    product_jancode.val($.trim(product_jancode.val()));
    //検索パラメータにJANコードを設定
    var param = {};
    param['jancode'] = product_jancode.val();
    param['customer_id'] = customer.val();
    //if (IsBlank(customer.val()) == true){
    //  alert("得意先名を入力してください。")
    //  return
    //}
    //商品情報を取得
    $.ajax({
      url : '/products/jancode_search',
      type : "POST",
      cache : false,
      dataType : "json",
      data : param,
      success : function(list){
        if(IsBlank(list) == false){
          if (ToNumber(elemProductId.val()) === Number(list.id)){
            //console.log('前回と同じ！！！')
            // 数量にフォーカス移動
            elemQuantity.focus();
          }else{
            //商品IDを表示
            elemProductId.val(list.id);
            //商品名を表示
            elemProductName.val(list.name);
            //単価を表示
            elemUnitPrice.val(addFigure(list.unit_price, elemUnitPrice.attr('dp')));
            elemUnitPrice.css('text-align','right');
            //原価を表示
            elemCostPrice.val(addFigure(list.cost_price, elemCostPrice.attr('dp')));
            elemCostPrice.css('text-align','right');
            //売価を表示
            elemSellingPrice.val(addFigure(0, elemCostPrice.attr('dp')));
            elemSellingPrice.css('text-align','right');
            // 数量にフォーカス移動
            elemQuantity.focus();
          }
        }else{
          if (Number(elemProductId.val()) === sundryItemsCode){
            //console.log('前回と同じ！！！')
            // 数量にフォーカス移動
            elemQuantity.focus();
          }else{
            //商品IDを表示
            elemProductId.val(sundryItemsCode);
            //商品名を表示
            elemProductName.val('');
            //単価を表示
            elemUnitPrice.val('');
            //原価を表示
            elemCostPrice.val('');
            //売価表示
            elemSellingPrice.val('');
            // 商品名にフォーカス移動
            elemProductName.focus();
          }
        }
      }, error: function(){
        //商品IDを表示
        elemProductId.val('');
        //商品名を表示
        elemProductName.val('接続失敗');
        //単価を表示
        elemUnitPrice.val('');
        //原価を表示
        elemCostPrice.val('');
        //売価表示
        elemSellingPrice.val('');
      }
    });
  });
  return
});