$(function() {
  /**
   * 数値の3桁カンマ区切り
   * 入力値をカンマ区切りにして返却
   * [引数]   numVal: 入力数値
   * [返却値] String(): カンマ区切りされた文字列
   *
   * 処理を適用するテキストボックスへのイベント設定
   * keydown : 数値のみ入力可
   * blur : カンマ区切り処理実施
   * focus : カンマ削除処理実施
   */

  // キー押下時
  $(document).on('keydown', '.NumberControl', function() {
    var k;
    k = event.keyCode;
    if ((k >= 48 && k <= 57) || (k >= 96 && k <= 105)){
      return true;
    } else if ((k === 109) || (k === 189) || (k === 110) || (k === 190) || (k === 109) || (k === 173) || (k === 9) || (k === 37) || (k === 39) || (k === 8) || (k === 46)){
      return true;
    }
    return false;
  });

  // フォーカスを取得したとき
  $(document).on('focus', '.NumberControl', function(){
    this.value = delFigure(this.value);
    $(this).css('text-align', 'left');
  });

  // フォーカスが離れたとき
  $(document).on('blur', '.NumberControl', function() {
    _value = this.value.replace(/[^0-9|.|-]/g, '');
    this.value = addFigure(_value, $(this).attr("dp"));
    $(this).css('text-align', 'right');
  });
  return;
});

// 画面表示時
$(window).on('load', function() {
  $('.NumberControl').each(function(i, o) {
    $(o).val(window.addFigure($(o).val(), $(o).attr("dp")));
    $(o).css('text-align', 'right');
  });
  $('.readonlynumber').each(function(i, o) {
    //$(o).val(CommonLib.addFigure($(o).val(), $(o).attr("dp")));
    $(o).css('text-align', 'right');
  });
});
