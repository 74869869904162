$(function() {
  const INPUT_GROUP_SUFFIX = "_input_group";
  const BARCODEREADER_SUFFIX = "_barcodereader";

  $.fn.BarcodeReader = function(){
    var elemBase = $(this);
    var elemBaseName = elemBase.attr("name");
    // 起動チェック
    if (IsBlank(elemBaseName) === true || IsBlank(elemBase.attr('nextelement'))){
      return false;
    }
    // 次カーソルを取得
    var elemBaseNextElement = $("#" + NextFocusElementId(elemBase.attr('id'), elemBase.attr('nextelement')));
    //グループ要素を作成
    elemBase.wrap('<div name="' + elemBaseName + INPUT_GROUP_SUFFIX + '" class="input-group"></div>');
    //バーコードリーダ起動用の要素を作成
    elemBase.after('<div class="input-group-append"><span class="btn btn-outline-secondary" oname="' + elemBaseName + BARCODEREADER_SUFFIX + '" type="button" id="button-addon2"><i class="fa fa-barcode"></i></span></div>');
    var elemBarcodeReader = $('span[oname="' + elemBaseName + BARCODEREADER_SUFFIX + '"]')
    //クリック時
    elemBarcodeReader.off('click');
    elemBarcodeReader.on('click', function(){
      var scan = (async function main() {
        // 対応フォーマットの取得
        const formats = await BarcodeDetector.getSupportedFormats();
        if(!formats.includes('qr_code')) {
          elemBase.focus();
          $('.modal-barcode').modal('hide');
          $('.modal-barcode').remove();
          alert('バーコード読み取りがサポートされていない機種構成です。');
          throw new Error('バーコード読み取りがサポートされていない機種構成です。');
        }
        // モーダルウィンドウ用の要素を追加する。
        $('.modal-barcode').remove();
        $('body').append('<div class="modal modal-barcode" style="border:2px"></div>');
        $('.modal-barcode').append('<div class="modal-barcode-video"></div>');
        $('.modal-barcode').append('<div class="modal-barcode-footer text-center"></div>');
        $('.modal-barcode-footer').append('<input type="button" class="modal-barcode-close btn btn-info" value="閉じる" />');
        $('.modal-barcode').modal('show');
        // 背面カメラへアクセスする
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: { exact: "environment" }
          }
        });
        // カメラをvideo要素と結びつける
        const video = document.createElement('video');
        video.srcObject = stream;
        // 画面にvideo要素を追加する（確認用）
        video.width = 1000;
        video.height = 1000;
        $('.modal-barcode-video').append(video);
        // 再生を開始する
        await video.play();
        // 検出をQRコードのみに限定する
        const detector = new BarcodeDetector({
        //  formats: ['qr_code']
        });

        // 一定時間ごと（ここでは500ミリ秒間隔）にQRコードの検出を試みる
        var IntervalId = setInterval(async () => {
          const detectionList = await detector.detect(video);
          for(const detected of detectionList) {
            elemBase.focus();
            elemBase.val(detected.rawValue);
            video.pause();
            elemBaseNextElement.focus();
            $('.modal-barcode').modal('hide');
            $('.modal-barcode').remove();
            clearInterval(IntervalId);
          }
        }, 500);

        // [閉じる]ボタンをクリック時
        $('.modal-barcode-close').off('click');
        $('.modal-barcode-close').on('click', function(){
          elemBase.focus();
          video.pause();
          $('.modal-barcode').modal('hide');
          $('.modal-barcode').remove();
          clearInterval(IntervalId);
        });
      })();
    });
  }
});
