$(function() {
	$.fn.Suggest = function(options){
		const INPUT_GROUP_SUFFIX = "_input_group";
		//const KEYWORD_SUFFIX = "_keyword";
		const CANCEL_SUFFIX = "_cancel";

		var defaults = {
			msModel : 'customer',
			msListCount : 10,
			msAjaxParam : 'keyword',
			msSpScroll : false,
			msMinLength : 1
		};

		var setting = $.extend(defaults, options);
		//var elm_list;
		var elm_active = -1;
		//var key_last_time = 0;
		var key_down_flg = false;
		var key_down_text;
		var click_flg = false;

		var elemBase = $(this);
		var elemBaseName = elemBase.attr("name");

		// 起動チェック
    if (IsBlank(elemBaseName)){
      return false;
    }
		var classFirstCursor = elemBase.attr("class").indexOf('firstcursor');
		// キーボードの種類を設定
		var attrInputmode = elemBase.attr("inputmode");
		//該当の要素を非表示
		elemBase.attr('type', 'hidden');
		//グループ要素を作成
		elemBase.after('<div oname="' + elemBaseName + INPUT_GROUP_SUFFIX + '" class="input-group"></div>');
		var MY_INPUT_GROUP = $('div[oname="' + elemBaseName + INPUT_GROUP_SUFFIX + '"]')
		//キーワード入力要素を作成
		if (classFirstCursor > 0) {
			MY_INPUT_GROUP.append('<input type="text" class="form-control firstcursor" oname="' + elemBaseName + INPUT_GROUP_SUFFIX + '" autocomplete="off" placeholder="入力して検索" >');
		} else {
			MY_INPUT_GROUP.append('<input type="text" class="form-control" oname="' + elemBaseName + INPUT_GROUP_SUFFIX + '" autocomplete="off" placeholder="入力して検索" >');
		}
		var MY_KEYWORD = $('input[oname="' + elemBaseName + INPUT_GROUP_SUFFIX + '"]');
		// キーボードの種類を設定
		if (IsBlank(attrInputmode) === false){
			MY_KEYWORD.attr('inputmode', attrInputmode);
		}
		//削除用の要素を作成
		MY_KEYWORD.after('<div class="input-group-append"><span oname = "' + elemBaseName + CANCEL_SUFFIX + '" class="input-group-text">×</span></div>');
		var MY_CANCEL = $('span[oname="' + elemBaseName + CANCEL_SUFFIX + '"]')
		//リスト要素を作成
		MY_INPUT_GROUP.next('[data-mysuggest]').remove();
		MY_INPUT_GROUP.after('<div data-mysuggest="true"><ul></ul></div>');

		MY_KEYWORD.css('margin-bottom', '0');

		var id = elemBase.val();

		if (id != ''){
			style_chenge(true);
			$.ajax({
				url : '/' + setting.msModel + '/' + id + '/show_json',
				type : "GET",
				cache : false,
				dataType : "json",
				success : function(list){
					MY_KEYWORD.val(list.name);
					elemBase.attr('hb_tek_tax_fraction', list.hb_tek_tax_fraction);
				},
				error: function(){
					MY_KEYWORD.val('表示に失敗しました。');
				}
			});
		}

		//クリック時
		MY_KEYWORD.off('click');
		MY_KEYWORD.on('click', function(){
			if(!setting.msSpScroll) return false;
			if(_ua.Mobile){
				var position = MY_KEYWORD.offset().top;
				$('body,html').animate({scrollTop: (position - 10)}, 300);
			}
		});

		//タブレットのタッチ時
		MY_INPUT_GROUP.next('[data-mysuggest]').on('touchstart', function(e){
			$(e.target).addClass('totch');
		});
		MY_INPUT_GROUP.next('[data-mysuggest]').on('touchend', function(e){
			$(e.target).removeClass('totch');
		});

		//コントロールの入力値をリアルタイム取得
		MY_KEYWORD.off('input');
		MY_KEYWORD.on('input', function(){
			if(click_flg) return false;
			MY_INPUT_GROUP.next('[data-mysuggest]').find('ul').css('width', MY_KEYWORD.outerWidth() + 'px');
			key_down_flg = false;
			if(MY_KEYWORD.val() == "" || setting.msMinLength > MY_KEYWORD.val().length){
				MY_INPUT_GROUP.next('[data-mysuggest]').find('ul').empty();
				elm_active = -1;
				return;
			}
			var param = {};
			param[setting.msAjaxParam] = MY_KEYWORD.val();
			$.ajax({
				url : '/' + setting.msModel + '/suggest',
				type : "POST",
				cache : false,
				dataType : "json",
				data : param,
				success : function(list){
					MY_INPUT_GROUP.next('[data-mysuggest]').find('ul').empty();
					$.each(list, function(i, value){
						if(i >= setting.msListCount) return false;
						MY_INPUT_GROUP.next('[data-mysuggest]').find('ul').append('<li oid=' + value.id + ' hb_tek_tax_fraction=' + value.hb_tek_tax_fraction + '>' + value.name + '</li>');
					});
				}
			});

			//Suggestのリストをマウスでクリックした時
			MY_INPUT_GROUP.next('[data-mysuggest]').on('mousedown', function(e){
				click_flg = true;
				MY_KEYWORD.blur();
				if($(e.target).html().indexOf("<li>") == -1 && $(e.target).html().indexOf("</li>") == -1){
					MY_KEYWORD.val($(e.target).text());
					elemBase.val($(e.target).attr('oid'));
					elemBase.attr('hb_tek_tax_fraction', $(e.target).attr('hb_tek_tax_fraction'));
				}
				elm_active = -1;
				MY_INPUT_GROUP.next('[data-mysuggest]').find('ul').empty();
				style_chenge(true);
				MY_KEYWORD.trigger('selected', MY_KEYWORD.val());
			});

			//キーワードからカーソルが離れた時
			MY_KEYWORD.off('blur');
			MY_KEYWORD.on('blur', function(){
				var code =  elemBase.val();
				if(code == ''){
					elemBase.val('');
					elemBase.attr('hb_tek_tax_fraction', '0');
					MY_KEYWORD.val('');
				}
				setTimeout(function(){
					elm_active = -1;
					click_flg = false;
					MY_INPUT_GROUP.next('[data-mysuggest]').find('ul').empty();
				}, 100);
			});

			//キーワード上でキーを押下した時
			MY_KEYWORD.off('keydown');
			MY_KEYWORD.on('keydown', function(evnt){
				if(!MY_INPUT_GROUP.next('[data-mysuggest]').find('ul')) return false;

				if(!key_down_flg){
					key_down_flg = true;
					key_down_text = MY_KEYWORD.val();
				}
				//↑キーと↓キーを押下
				if(evnt.keyCode == 40 || evnt.keyCode == 38){
					if(!MY_INPUT_GROUP.next('[data-mysuggest]').find('ul').find('li').length) return false;
					var elm_cnt = MY_INPUT_GROUP.next('[data-mysuggest]').find('ul').find('li').length;
					if(evnt.keyCode == 40){
						if((elm_cnt-1) > elm_active){
							elm_active++;
						}
					}
					// ↑キーを押下
					if(evnt.keyCode == 38){
						if(elm_active >= 0){
							elm_active--;
						}
					}

					MY_INPUT_GROUP.next('[data-mysuggest]').find('ul').find('li').removeClass('totch');

					if(elm_active >= 0){
						MY_INPUT_GROUP.next('[data-mysuggest]').find('ul').find('li').eq(elm_active).addClass('totch');
					}

					var text = MY_INPUT_GROUP.next('[data-mysuggest]').find('ul').find('li').eq(elm_active).text();
					if(evnt.keyCode == 38 && elm_active == -1) text = key_down_text;
					MY_KEYWORD.val(text);

				}
				//Enterキーを押下時
				if(evnt.keyCode == 13){
					if(elm_active >= 0){
						var id = MY_INPUT_GROUP.next('[data-mysuggest]').find('ul').find('li').eq(elm_active).attr('oid');
						var tax_fraction = MY_INPUT_GROUP.next('[data-mysuggest]').find('ul').find('li').eq(elm_active).attr('hb_tek_tax_fraction');
						elemBase.val(id);
						elemBase.attr('hb_tek_tax_fraction', tax_fraction);
						MY_INPUT_GROUP.next('[data-mysuggest]').find('ul').empty();
						elm_active = -1;

						style_chenge(true);
						MY_KEYWORD.trigger('selected', MY_KEYWORD.val());
					}
				}
			});
		});
		MY_CANCEL.off('click');
		MY_CANCEL.on('click', function(){
			style_chenge(false);
			MY_KEYWORD.val('');
			elemBase.val('');
			elemBase.attr('hb_tek_tax_fraction', '0');
		});

		var _ua = (function(u){
			return {
				Tablet:(u.indexOf("windows") != -1 && u.indexOf("touch") != -1 && u.indexOf("tablet pc") == -1)
					|| u.indexOf("ipad") != -1
					|| (u.indexOf("android") != -1 && u.indexOf("mobile") == -1)
					|| (u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1)
					|| u.indexOf("kindle") != -1
					|| u.indexOf("silk") != -1
					|| u.indexOf("playbook") != -1,
				Mobile:(u.indexOf("windows") != -1 && u.indexOf("phone") != -1)
					|| u.indexOf("iphone") != -1
					|| u.indexOf("ipod") != -1
					|| (u.indexOf("android") != -1 && u.indexOf("mobile") != -1)
					|| (u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1)
					|| u.indexOf("blackberry") != -1
			}
		})(window.navigator.userAgent.toLowerCase());

		//表示の切り替え
		function style_chenge(arg){
			if (arg == true){
				MY_KEYWORD.attr('readonly', true);
				MY_KEYWORD.css({'background-color':'#F5F5F5', 'cursor': 'not-allowed'})
			}else{
				MY_KEYWORD.attr('readonly', false);
				MY_KEYWORD.css({'background-color': '#FFFFFF', 'cursor': 'default'})
			};
		};
	}
});